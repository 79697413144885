.splide {
  padding: 20px 0;
}

.carrossel-splide-custom .splide__slide img {
  -webkit-transform: scale(.9);
  -webkit-transform-origin: center center;
  transform: scale(.9);
  transform-origin: center center;
}

.carrossel-splide-custom .splide__slide.is-active img {
  -webkit-transform: scale(1);
  -webkit-box-shadow: 0px 6px 19px -8px rgba(255, 0, 0, 1);
  transform: scale(1);
  box-shadow: 0px 6px 19px -8px rgba(255, 0, 0, 1);
}

.carrossel-splide-custom .splide__slide img {
  width: 100%;
  border-radius: 8px;
  transition: transform 400ms;
}

.left {
  text-align: left;
}