@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

html {
    scroll-behavior: smooth
}

main {
    transition: top 1500ms cubic-bezier(.5, .33, .3, .9) 200ms;
}

.borderbutton {
    border: 2px solid #0061FE;
    border-radius: 40px;
    padding: 30px;
    font-family: 'Poppins', 'sans-serif';
}

.borderbutton-basic {
    border-radius: 40px;
    font-family: 'Poppins', 'sans-serif';
}

.borderbutton-cards {
    border: 2px solid #0061FE;
    border-radius: 40px;
    padding: 18px;
    font-family: 'Poppins', 'sans-serif';
}

.button-gradient {
    background: linear-gradient(90deg, rgba(21, 26, 67, 1) 0%, rgba(33, 48, 170, 1) 100%);
}

.card-gradient {
    background: radial-gradient(circle, rgba(81, 114, 187, 1) 0%, rgba(33, 43, 64, 1) 78%);
}

.line-gradient {
    background: linear-gradient(90deg, rgba(21, 26, 67, 1) 0%, rgba(0, 97, 254, 1) 24%, rgba(0, 97, 254, 1) 75%, rgba(21, 26, 67, 1) 100%);
}

.splide__pagination__page {
    background-color: #DDD9D9;
    height: 12px;
    width: 12px;
}

.splide__pagination__page.is-active {
    background-color: #0061FE;
    width: 8px;
    height: 25px;
    margin-bottom: 6px;
    margin-top: 6px;
    border-radius: 10px;
}

.custom-splide .splide__pagination__page.is-active {
    background-color: #0061FE;
    width: 25px;
    height: 8px;
    margin-bottom: 6px;
    margin-top: 6px;
    border-radius: 10px;
}

.splide__arrow {
    display: none;
}

.splide__arrow svg {
    fill: #0061FE;
}

.custom-arrow .splide__arrow {
    display: flex;
}

.backScreen {
    overflow: hidden;
}

.splide__slide {
    overflow: hidden;
    position: relative;
}

.slicer-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, transparent 50%, #fff 50%);
    transform-origin: 0 50%;
    animation: slicerAnimation 1s linear infinite;
}

.underlineEffect {
    position: relative;
    text-decoration: none;
}

.underlineEffect::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: #fff;
    border-radius: 10px;
    transition: .3s;
}

.underlineEffect:hover::before {
    width: 100%;
    background-color: #0061FE;
}

@keyframes slideDown {
    from {
        transform: translateY(-100%);
    }

    to {
        transform: translateY(0);
    }
}

@keyframes slideUp {
    from {
        transform: translateY(0);
    }

    to {
        transform: translateY(100%);
    }
}

.animate-slide-down {
    animation: slideDown 0.7s ease-out;
}

.animate-slide-up {
    animation: slideUp 0.7s ease-out;
}

@keyframes slicerAnimation {
    0% {
        transform: scaleX(0);
    }

    100% {
        transform: scaleX(1);
    }
}

@media (max-width: 730px) {
    .splide__pagination__page {
      display: none;
    }

    .splide__pagination__page.is-active {
     display: none;
    }
}